<template>
  <div class="bigDiv">
    <div class="moreList">

      <div class="infoTitle">
        <div class="title">
          <span class="titles"></span> {{foodInfo.name}}
        </div>
        <div class="info">
          <span class="A">每一百克可食用部分</span>
          <span class="B">{{foodInfo.name}}的所有营养素</span>
        </div>
      </div>
      <div class="listTit">
        <div>营养元素</div>
        <div>含量</div>
      </div>
      <div v-for=" item in foodInfo.info" :key="item.key">
        <div class="li">
          <div class="left">{{item.key}}</div>
          <div class="right">{{item.value}}</div>

        </div>
        <div class="xian">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWechatConfig } from "@/api/yangsheng";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      foodInfo: {}
    }
  },
  created () {
    this.foodInfo = JSON.parse(sessionStorage.getItem("foodInfo"));
    console.log(this.foodInfo);
  },
  methods: {
    getWechatConfig () {

      const query = {
        company_id: 1,
        url: window.location.href
      }
      getWechatConfig(query).then(res => {
        const agent = res.data.data
        if (res.data.code === 200) {
          this.$nextTick(() => {

          })
          wx.config({
            debug: agent.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: agent.appId, // 必填，公众号的唯一标识
            timestamp: agent.timestamp, // 必填，生成签名的时间戳
            nonceStr: agent.nonceStr, // 必填，生成签名的随机串
            signature: agent.signature,// 必填，签名
            //1.4.0的分享接口,注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
            //     "onMenuShareAppMessage", //老版本分享接口。
            //     "onMenuShareTimeline" //老版本分享接口。
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData,'onMenuShareAppMessage", "onMenuShareTimeline"] // 必填，需要使用的JS接口列表
          });

          this.share()
        }

      }
      )
    },
    share () {
      this.$nextTick(() => {
        wx.ready(function () {
          // 分享给朋友
          wx.updateAppMessageShareData({
            title: '食物搜索', // 分享标题
            link: 'https://kf.hshwhkj.com/foodList', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '营养食疗专业查询工具', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          });
          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: '食物搜索', // 分享标题
            link: 'https://kf.hshwhkj.com/foodList', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '营养食疗专业查询工具', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          })

        });
        wx.error(res => {
          console.log('微信分享错误信息：', res)
        })
      })



    },
  },
  mounted () {
    this.getWechatConfig()
  },
  watch: {

  },
  computed: {

  },
  filters: {

  }
}
</script>

<style scoped lang='less'>
.bigDiv {
  .moreList {
    min-height: 120px;
    overflow: hidden;
    .listTit {
      box-sizing: border-box;
      background-color: #f8f8f8;
      height: 50px;
      line-height: 50px;
      color: #999;
      font-size: 14px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 0 10px;
    }
    .li {
      display: flex;
      width: 350px;
      justify-content: space-between;
      margin: 10px auto;
      font-size: 16px;
    }
    .xian {
      width: 350px;
      height: 0.8px;
      background-color: #f2f2f2;
    }
  }
  .infoTitle {
    background-color: #fff;
    border-radius: 10px;
    width: 350px;
    margin: 0 auto;
    min-height: 40px;
    overflow: hidden;
    .title {
      font-weight: 700;
      font-size: 16px;
      margin: 10px;
      .titles {
        display: inline-block;
        width: 4px;
        height: 16px;
        background-color: #00a37c;
        position: relative;
        top: 3px;
        border-radius: 30px;
      }
    }
    .info {
      font-size: 14px;
      margin: 10px;
      .A {
        border-bottom: 1px solid #1ac69d;
        color: #1ac69d;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 750px;
    margin: 0 auto;
    .moreList {
      min-height: 120px;
      overflow: hidden;
      .listTit {
        box-sizing: border-box;
        background-color: #f8f8f8;
        height: 50px;
        line-height: 50px;
        color: #999;
        font-size: 14px;
        display: flex;
        width: 750px;
        justify-content: space-between;
        padding: 0 10px;
      }
      .li {
        display: flex;
        width: 750px;
        justify-content: space-between;
        margin: 10px auto;
        font-size: 16px;
      }
      .xian {
        width: 350px;
        height: 0.8px;
        background-color: #f2f2f2;
      }
    }
    .infoTitle {
      background-color: #fff;
      border-radius: 10px;
      width: 750px;
      margin: 0 auto;
      min-height: 40px;
      overflow: hidden;
      .title {
        font-weight: 700;
        font-size: 16px;
        margin: 10px;
        .titles {
          display: inline-block;
          width: 4px;
          height: 16px;
          background-color: #00a37c;
          position: relative;
          top: 3px;
          border-radius: 30px;
        }
      }
      .info {
        font-size: 14px;
        margin: 10px;
        .A {
          border-bottom: 1px solid #1ac69d;
          color: #1ac69d;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>